import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../services/common/login.service';
import { MatDialog} from '@angular/material';

import { NgProgress } from "ngx-progressbar";

import * as CryptoJS from 'crypto-js';
import { ForgotPasswordDialog } from './forgotPassword.component';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [
    './signin.component.scss'
  ]
})
export class SigninComponent implements OnInit {

  public form: FormGroup;

  IsUserLogin: boolean = false;
  errorMsg: String;
  invalid: boolean;
  message: string;
  closeOnNavigation: boolean;
  saveMessage: boolean = false;
  expanded: boolean = true;
  showSpinner: boolean;
  sub: any = '';
  Session: any = '';
  FormDataList = [];
  listData = [];
  proposedTableName = [];
  AccessAppMenuList = [];
  loginBtnTitle="Login";
  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService, public dialog: MatDialog, public progress: NgProgress) { }



  ngOnInit() {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
       password: [null, Validators.compose([Validators.required])],
       type: ['1', Validators.compose([Validators.required])]
    });

  }

  onSubmit() {
    this.loginBtnTitle="Logging....";
    this.progress.start();
    this.loginService.showSpinner = true;


    this.loginService.doLogin({
      
      'username': this.form.value.username,
      'password': this.form.value.password,
      'type': this.form.value.type
    }).subscribe(
      (data) => {
			console.log('TCL: SigninComponent -> onSubmit -> data', data)


        
        if (data["error"] === false) {
          
          var bytes = CryptoJS.AES.decrypt(data['Empdata'],"KTL@123456");
          var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
					console.log('TCL: SigninComponent -> onSubmit -> decryptedData', decryptedData)
          
          if (decryptedData['emp_role'] == 'Power User') {
            this.loginBtnTitle="Redirecting....";
            this.invalid = false;
            this.router.navigate(['/dashboard']);
            
            this.progress.done();
          }
          else if (decryptedData['emp_role'] == 'End User') {
            this.loginBtnTitle="Redirecting";
            this.loginService.showSpinner = false;
            this.invalid = true;
            this.progress.done();
            this.message='Invalid';
  
          }
        } else {
          this.loginBtnTitle="Login";
          this.invalid = true;
         
          this.progress.done();
          this.message=data["message"];
        }

       
      },
      (error) => {
        this.invalid = true;
        this.progress.done();
        
        this.message = "Please try again!";
      }
    )
  }

  openForgotPassword(){
    

    let dialogRef = this.dialog.open(ForgotPasswordDialog, {
      width: '500px',
      id: 'forgotPasswordDialog',
      closeOnNavigation: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
    
    
  }

}